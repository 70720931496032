import styled from "@emotion/styled";
import bg from "../../assets/background.png";
import logoCloud from "../../assets/logo_cloud.png";

export const DISPLAY_SIZES = {
  MOBILE_S: "320px",
  MOBILE_M: "375px",
  MOBILE_L: "425px",
  TABLET: "768px",
  LAPTOP: "1024px",
  LAPTOP_L: "1440px",
  DESKTOP: "1920px",
  DESKTOP_L: "2560px",
  DESKTOP_XL: "3840px",
};

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  position: relative;
  font-family: "Atma";
  font-weight: 600;
  background-color: var(--bg-blue);
  /* overflow-y: auto; */
`;

export const BirdImg = styled.img`
  position: absolute;
  z-index: 3;
  top: 20vh;
  right: -10vw;
  width: 80vw;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    width: 140vw;
    right: -40vw;
    top: 30vh;
  }
`;

export const Cloud1 = styled.img`
  position: absolute;
  z-index: 4;
  top: 50vh;
  right: 0;
  width: 35vw;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    top: 60vh;
    width: 50vw;
  }
`;

export const Cloud2 = styled.img`
  position: absolute;
  z-index: 2;
  top: 30vh;
  left: 0;
  width: 40vw;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    top: 40vh;
    width: 50vw;
  }
`;

export const Content = styled.div`
  overflow-x: clip;
  /* background-color: rgba(0, 0, 0, 0.2); */
`;

export const Header = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;

  justify-content: space-between;

  padding: 24px 32px;
  /* background-color: rgba(0, 0, 0, 0.2); */

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    padding: 8px;
  }
`;

export const HeaderLogo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  /* background-image: url(${logoCloud});
  background-size: cover; */
  padding: 36px 36px;
  position: relative;
`;

export const BgCloud = styled.img`
  position: absolute;
  top: -4px;
  left: 0;
  width: 100%;
  z-index: 3;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    top: 0;
  }
`;

export const Logo = styled.img`
  height: 48px;
  width: 48px;
  position: relative;
  z-index: 9;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    height: 36px;
    width: 36px;
  }
`;

export const LogoLabel = styled.div`
  font-family: "Atma";
  font-size: 32px;
  position: relative;
  z-index: 9;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    font-size: 28px;
  }
`;

export const HeaderBtn = styled.button<{ disabled?: boolean }>`
  font-family: "Atma";
  font-weight: 600;
  font-size: 32px;
  background-color: transparent;
  border-radius: 16px;
  padding: 4px 24px;
  color: #000;
  border: 4px solid #000;
  transition: 0.2s;
  cursor: pointer;
  background-color: #ffe500;
  box-shadow: 4px 4px 0px 0px #000;
  position: relative;
  z-index: 9;

  :hover {
    background-color: #fff284;
  }

  :active {
    box-shadow: unset;
    /* margin-top: 4px; */
    transform: translateX(4px) translateY(4px);
  }

  ${({ disabled }) =>
    disabled
      ? `
    background-color: #CFCFCF;
    color: #5E5E5E;

    :hover {
      background-color: #CFCFCF;
    }

    :active {
      transform: translateX(0px) translateY(0px);
      box-shadow: 4px 4px 0px 0px #000;
    }
  `
      : ""}

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    padding: 4px 16px;
    font-size: 26px;
  }
`;

export const Section = styled.section`
  padding-top: 104px;
  height: calc(100vh - 104px);
  background-color: var(--bg-blue);
  position: relative;
  /* height: 100vh; */
  width: 100vw;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    height: 100vh;
  }
`;

export const FirstSection = styled(Section)`
  background-image: url(${bg});
  background-size: cover;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    background-position: center;
    overflow: hidden;
  }
`;

export const MainLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 32px;
  height: 50vh;
  width: 50vh;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    height: 50vh;
  }
`;

export const InfoContainer = styled.div`
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  z-index: 99;
  position: relative;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    gap: 16px;
    flex-wrap: wrap;
  }
`;

export const Box = styled.div`
  text-align: center;
  border: 4px solid var(--black);
  border-radius: 16px;
  padding: 16px 48px;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 4px 4px 0px 0px #000;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    padding: 8px 16px;
  }
`;

export const Box1 = styled(Box)`
  background-color: #eee3ff;
`;
export const Box2 = styled(Box)`
  background-color: #fce3df;
`;
export const Box3 = styled(Box)`
  background-color: #e2ffd0;
`;

export const Tokenomics = styled.img`
  width: 12vw;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    position: absolute;
    top: 50vh;
    z-index: 5;
    width: 35vw;
    right: 0;
  }
`;

export const BoxLabel = styled.div`
  font-size: 20px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    font-size: 16px;
  }
`;

export const BoxValue = styled.div`
  font-size: 48px;
  /* margin-top: 8px; */
  font-family: "Bakbak One";

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    font-size: 32px;
  }
`;

export const BoxValueSub = styled.span`
  position: relative;
  font-size: 32px;
  top: 4px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    font-size: 24px;
  }
`;

export const StoryContainer = styled.div`
  border: 4px solid var(--black);
  box-shadow: 4px 4px 0px 0px #000;
  border-radius: 16px;
  padding: 24px 40px;
  background-color: #fff;
  text-align: center;
  width: 50vw;
  margin: auto;
  /* margin-top: 50vh; */
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 10vh;
  z-index: 10;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    bottom: unset;
    top: 20vh;
    width: 90vw;
    box-sizing: border-box;
  }
`;

export const StoryTitle = styled.div`
  font-size: 48px;
  line-height: 42px;
  letter-spacing: 0.08em;
  margin-bottom: 24px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    font-size: 28px;
  }
`;

export const StoryText = styled.div`
  font-size: 22px;
  line-height: 42px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    font-size: 16px;
    line-height: 28px;
  }
`;

export const Feather = styled.img`
  width: 20vw;
  left: 0;
  top: 30vh;
  position: absolute;
`;

export const Cloud3 = styled.img`
  position: absolute;
  z-index: 2;
  top: 80vh;
  left: 0;
  width: 40vw;
`;

export const ThirdSection = styled(Section)`
  background: linear-gradient(180deg, var(--bg-blue), 30%, #fff);
`;

export const ThirdSectionTitle = styled.div`
  margin-top: 12vh;
  position: relative;
  z-index: 10;

  font-size: 48px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 0.08em;
  text-align: center;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    margin-top: 0;
    font-size: 32px;
    margin-bottom: 18vh;
  }
`;

export const QuestionsContainer = styled.div`
  position: relative;
  z-index: 4;
  width: 55vw;
  margin: auto;
  margin-top: 64px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    width: 90vw;
    box-sizing: border-box;
  }
`;

export const FaqImg = styled.img`
  width: 16vw;
  left: -14vw;
  bottom: 1vh;
  position: absolute;
  z-index: 5;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    left: 0;
    width: 30vw;
    /* bottom: -18vh; */
    top: -19vh;
  }
`;

export const QuestionContainer = styled.div`
  background: #cac0d4;
  padding: 12px 16px;
  border-radius: 8px;
  width: 55vw;
  margin: auto;
  margin-bottom: 24px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    width: 90vw;
    box-sizing: border-box;
  }
`;

export const Question = styled.div`
  font-size: 28px;
  font-weight: 600;
  line-height: 42px;
  text-align: left;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    font-size: 22px;
  }
`;

export const Answer = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 42px;
  text-align: left;
  margin-left: 24px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    font-size: 20px;
    line-break: anywhere;
  }
`;

export const URL = styled.a`
  text-decoration: none;
  color: #0032b4;
  margin: auto 6px;
`;

export const SocialContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 48px;
  margin-top: 16px;
  margin-bottom: 8px;
`;

export const Social = styled.img`
  width: 160px;
  cursor: pointer;
`;

export const SocialText = styled.div`
  font-size: 48px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 0.08em;
  text-align: center;
  margin-bottom: 24px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    font-size: 36px;
  }
`;
