import React, { useCallback, useEffect, useState } from "react";
import {
  Answer,
  BgCloud,
  BirdImg,
  Box1,
  Box2,
  Box3,
  BoxLabel,
  BoxValue,
  BoxValueSub,
  Cloud1,
  Cloud2,
  Cloud3,
  Container,
  Content,
  FaqImg,
  Feather,
  FirstSection,
  Header,
  HeaderBtn,
  HeaderLogo,
  InfoContainer,
  Logo,
  LogoLabel,
  MainLogoContainer,
  Question,
  QuestionContainer,
  QuestionsContainer,
  Section,
  Social,
  SocialContainer,
  SocialText,
  StoryContainer,
  StoryText,
  StoryTitle,
  ThirdSection,
  ThirdSectionTitle,
  Tokenomics,
  URL,
} from "./Home.styles";
import logo from "../../assets/logo.png";
import logoCloud from "../../assets/logo_cloud.png";
import tokenomics from "../../assets/tokenomics.png";
import birdImg from "../../assets/bird.png";
import cloud1 from "../../assets/cloud1.png";
import cloud2 from "../../assets/cloud2.png";
import cloud3 from "../../assets/cloud3.png";
import feather from "../../assets/feather.png";
import faqImg from "../../assets/faq_gfx.png";
import twitter from "../../assets/twitter.png";
import twitterHoverImg from "../../assets/twitter_hover.png";
import telegram from "../../assets/telegram.png";
import telegramHoverImg from "../../assets/telegram_hover.png";

const TWITTER_URL = "https://twitter.com/StarkyCoin";
const TELEGRAM_URL = "https://t.me/StarkyTheStock";
const BUY_NOW_URL =
  "https://app.avnu.fi/en?tokenFrom=0x49d36570d4e46f48e99674bd3fcc84644ddd6b96f7c741b1562b82f9e004dc7&tokenTo=0x068a5dF4eA6A04522F483ebF2fF3F85f410a4e0827fF7b60A6015d2A8d07a6A9&amount=0.1";

const getZeroes = (num) => {
  return num.toString().match(/(\.0*)/)[0].length - 1;
};

const getFormattedNumber = function (
  num,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2
) {
  // `isNil` check for `null` or `undefined`
  // `num !== num` check for `NaN`
  if (!num) {
    return Number(0).toFixed(2);
  }

  // Less than 100k
  if (num < 100000) {
    return num.toLocaleString(undefined, {
      minimumFractionDigits,
      maximumFractionDigits,
    });
  }

  // Less than 1 million
  if (num < 1000000) {
    return `${(num / 1000).toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    })}K`;
  }

  // Million, Billion, Trillion, quadrillion, Quintillion, sextillion, Septillion
  const units = ["M", "B", "T", "q", "Q", "s", "S"],
    unit = Math.floor((num / 1.0e1).toFixed(0).toString().length),
    r = unit % 3,
    x = Math.abs(Number(num)) / Number(Number("1.0e+" + (unit - r)).toFixed(2));

  return x.toFixed(2) + "" + units[Math.floor(unit / 3) - 2];
};

function Home() {
  const [twitterHover, setTwitterHover] = useState(false);
  const [telegramHover, setTelegramHover] = useState(false);

  const [price, setPrice] = useState(0);
  const [marketcap, setMarketcap] = useState(0);

  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    const getData = async () => {
      const data = await fetch(
        "https://api.dexscreener.com/latest/dex/pairs/starknet/0x0239ea23c8b19aa8fd275c2cfd146aa5a6ad50c22d738f5100b4df38d9e12ab9"
      ).then((res) => res.json());

      const { priceUsd, fdv } = data?.pair || {};

      setPrice(priceUsd);
      setMarketcap(fdv);
    };

    getData();
  }, []);

  const isMobile = width <= 768;

  const renderPrice = useCallback(() => {
    if (!price) {
      return "-";
    }

    const zeroes = getZeroes(price);

    return (
      <>
        $0.0
        <BoxValueSub>{zeroes}</BoxValueSub>
        {String(price).substring(zeroes + 2)}
      </>
    );
  }, [price]);

  return (
    <Container>
      <Header>
        <HeaderLogo>
          <BgCloud src={logoCloud} />
          <Logo src={logo} />
          <LogoLabel>STARKY</LogoLabel>
        </HeaderLogo>

        <HeaderBtn onClick={() => window.open(BUY_NOW_URL, "_blank")}>
          BUY NOW
        </HeaderBtn>
      </Header>
      <Content>
        <FirstSection>
          <BirdImg src={birdImg} />
          <Cloud1 src={cloud1} />
          <Cloud2 src={cloud2} />
          <MainLogoContainer />
          {isMobile ? <Tokenomics src={tokenomics} /> : null}

          <InfoContainer>
            <Box1>
              <BoxLabel>TOTAL HOLDERS</BoxLabel>
              <BoxValue>-</BoxValue>
            </Box1>
            <Box2>
              <BoxLabel>MARKET CAP</BoxLabel>
              <BoxValue>${getFormattedNumber(marketcap) || "-"}</BoxValue>
            </Box2>
            <Box3>
              <BoxLabel>$STARKY PRICE</BoxLabel>
              <BoxValue>{renderPrice()}</BoxValue>
            </Box3>
            {!isMobile ? <Tokenomics src={tokenomics} /> : null}
          </InfoContainer>
        </FirstSection>

        <Section>
          <Feather src={feather} />
          <Cloud3 src={cloud3} />
          <StoryContainer>
            <StoryTitle>THE STARKY STORY</StoryTitle>
            <StoryText>
              Starky the stork is the best baby nanny in the whole of Starknet.
              Fortune tellers from across the Starknet have confirmed that
              $STARKY is the essential token you need so that you, your
              children, your family and all your bags will receive plentiful
              blessings, rewards and money in this Starknet year. This is not
              just a token. $STARKY will also ward off all evils that are
              planned against you by deflecting the bad luck with lucky
              feathers. Join the $STARKY clan and know the STARK difference.
            </StoryText>
          </StoryContainer>
        </Section>

        <ThirdSection>
          <ThirdSectionTitle>FREQUENTLY ASKED QUESTIONS</ThirdSectionTitle>

          <QuestionsContainer>
            <FaqImg src={faqImg} />

            <QuestionContainer>
              <Question>1. LOOKING TO DELIVER? HERE'S OUR ADDRESS.</Question>
              <Answer>
                $STARKY contract address:
                0x068a5dF4eA6A04522F483ebF2fF3F85f410a4e0827fF7b60A6015d2A8d07a6A9
              </Answer>
            </QuestionContainer>
            <QuestionContainer>
              <Question>2. HOW MANY $STARKY CAN STARKNET HOLD?</Question>
              <Answer>100T total supply</Answer>
            </QuestionContainer>
            <QuestionContainer>
              <Question>3. NOT FROM AROUND HERE? BRIDGE TO STARKNET!</Question>
              <Answer>
                Simply check
                <URL
                  href="https://www.starknet-ecosystem.com/en"
                  target="_blank"
                >
                  https://www.starknet-ecosystem.com/en
                </URL>
                for list of bridges that support Starknet. Starky's tips: Make
                sure you have an EVM wallet filled with ETH and a Starknet
                wallet ready at hand to bridge across seamlessly.
              </Answer>
            </QuestionContainer>
          </QuestionsContainer>

          <SocialContainer>
            <Social
              onClick={() => window.open(TWITTER_URL, "_blank")}
              onMouseOver={() => setTwitterHover(true)}
              onMouseLeave={() => setTwitterHover(false)}
              src={twitterHover ? twitterHoverImg : twitter}
            />
            <Social
              onClick={() => window.open(TELEGRAM_URL, "_blank")}
              onMouseOver={() => setTelegramHover(true)}
              onMouseLeave={() => setTelegramHover(false)}
              src={telegramHover ? telegramHoverImg : telegram}
            />
          </SocialContainer>

          <SocialText>CONNECT WITH STARKY</SocialText>
        </ThirdSection>
      </Content>
    </Container>
  );
}

export default Home;
